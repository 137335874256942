import "./Transactions.css";

import { DateTime } from "luxon";

import { DeleteForever, MoreVert } from "@mui/icons-material";
import { Divider, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Typography } from "@mui/material";

import { JournalRecordItem } from "../../client";
import { formatMoneyAmount } from "../../utils";

export const JournalRecord = (props: {
    record: JournalRecordItem;
    onDelete: (id: number) => void;
}) => {
    return (
        <div className="Journal-Record">
            <div className="Journal-Record-Header">
                <div className="Journal-Record-Date">
                    <span>
                        {DateTime.fromISO(props.record.created_at).toFormat(
                            "LLL dd yyyy",
                        )}
                    </span>
                </div>
                <div className="Journal-Record-Description">
                    {/* <b>Description: </b> <br /> */}
                    <span>{props.record.description}</span>
                </div>
                <div className="JournalRecord-Total">
                    <div className="Journal-Record-Total-Domestic">
                        {props.record.domestic
                            ? formatMoneyAmount(props.record.domestic_total)
                            : "-"}
                    </div>
                </div>
            </div>
            <div className="PostingRow-Controls">
                <Dropdown>
                    <MenuButton>
                        <MoreVert fontSize="small" />
                    </MenuButton>
                    <Menu>
                        <MenuItem
                            color="danger"
                            onClick={(_) =>
                                props.onDelete(props.record.record_id || -1)
                            }>
                            <DeleteForever /> Delete
                        </MenuItem>
                    </Menu>
                </Dropdown>
            </div>
            <Divider> Splits </Divider>
            {props.record.splits.map((s) => (
                <div
                    className={
                        "Split-Record " + (s.debit ? "" : "Split-Credit")
                    }>
                    <span>{s.account_name}</span>
                    <span>{formatMoneyAmount(s.amount)}</span>
                </div>
            ))}
        </div>
    );
};
