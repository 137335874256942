import { Button, Typography } from "@mui/material";

type PaginationProps = {
    onPrev?: () => void;
    onNext?: () => void;
    onPage?: () => void;
    page: number;
    total?: number;
    className?: string;
};

export const ListPagination = (props: PaginationProps) => {
    return (
        <div className={"Pagination " + props.className}>
            <Button
                disabled={props.page <= 1}
                onClick={() => props.onPrev && props.onPrev()}>
                Previous
            </Button>
            <Typography>
                {props.page} {props.total && "/" + props.total}
            </Typography>
            <Button
                disabled={props.page === props.total}
                onClick={() => props.onNext && props.onNext()}>
                Next
            </Button>
        </div>
    );
};
