import "./App.css";

import { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

import { client } from "@hey-api/client-fetch";
import { ThemeProvider } from "@mui/joy";

import { getUserInfoApiV1UserGet as checkToken } from "./client";
import { Header } from "./elements/Header/Header";
import book_icon from "./images/book_2_24dp_FILL0_wght400_GRAD0_opsz24.png";
import transaction_icon from "./images/contract_24dp_FILL0_wght400_GRAD0_opsz24.png";
import dashboard_icon from "./images/dashboard_24dp_FILL0_wght400_GRAD0_opsz24.png";
import { theme } from "./theme";
import { clearToken, getToken } from "./utils";

client.interceptors.request.use((request, _) => {
    request.headers.set("token", "asdf");
    return request;
});

function App() {
    const getLinkClass = (attrs: { isActive: boolean; isPending: boolean }) => {
        return attrs.isActive ? "Link-Active" : "";
    };
    const navigate = useNavigate();
    const token = getToken();
    useEffect(() => {
        if (token === "") {
            navigate("/signin");
        }
        checkToken({ headers: { token: token } })
            .then((response) => {
                if (response.status !== 200) {
                    navigate("/signin");
                }
                if (response.data?.companies.length === 0) {
                    navigate("/create-company");
                }
            })
            .catch((reason: any) => {
                if (reason?.status === 401) {
                    clearToken();
                    navigate("/signin");
                }
            });
    }, [navigate, token]);

    return (
        <div className="App">
            <Header />
            <div className="Page-Wrapper">
                <nav>
                    <NavLink to="/dashboard" className={getLinkClass}>
                        <img src={dashboard_icon} alt="" />
                    </NavLink>
                    <NavLink to="/transaction" className={getLinkClass}>
                        <img src={transaction_icon} alt="" />
                    </NavLink>
                    <NavLink to="/account" className={getLinkClass}>
                        <img src={book_icon} alt="" />
                    </NavLink>
                </nav>
                <div className="Page-Content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default App;
