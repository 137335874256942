import { useAppDispatch } from "../../app/hooks";
import { GetAccountsApiV1AccountListGetResponse as getAccountsResponse } from "../../client";
import { MonetaryInput } from "../../elements/FormElements/CurrencyInput";
import { Posting } from "./Schema";
import { changePosting, deletePosting } from "./transactionFormSlice";

interface SplitRecordParams {
    accounts: getAccountsResponse;
    data: Posting;
}

export const SplitRecord = (props: SplitRecordParams) => {
    const dispatch = useAppDispatch();

    const currencyInfo = props.accounts
        .map((ag) => ag.items || [])
        .reduce((prev, curr) => [...prev, ...curr])
        .find((acc) => acc.id === Number(props.data.account_id))?.currency_info;

    const currency = currencyInfo?.short_code || "USD";

    return (
        <div className="Split-Record">
            <select
                className="Split-Record-Account-Input"
                value={props.data.account_id}
                onChange={(e) =>
                    dispatch(
                        changePosting({
                            id: props.data.posting_id,
                            key: "account_id",
                            value: e.target.value,
                        }),
                    )
                }>
                <option value="-1">Not choosen</option>
                {props.accounts.map((acc_group) => (
                    <optgroup
                        key={acc_group.account_type}
                        label={acc_group.title}>
                        {acc_group.items?.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
            <MonetaryInput
                className="Split-Record-Amount-Input"
                value={props.data.amount}
                onChange={(v) =>
                    dispatch(
                        changePosting({
                            id: props.data.posting_id,
                            key: "amount",
                            value: v,
                        }),
                    )
                }
                onFocus={(e) => e.target.select()}
                currencyCode={currency}
            />
            <button
                onClick={(e) =>
                    dispatch(deletePosting({ id: props.data.posting_id }))
                }
                className="Split-Record-Btn-Delete">
                ❌
            </button>
        </div>
    );
};
