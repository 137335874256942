import "./AccountItem.css";

import { produce } from "immer";
import { DateTime } from "luxon";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, CircularProgress, Input, Skeleton } from "@mui/material";

import {
    AccountPeriod,
    getAccountBasicInfoApiV2AccountAccountIdBasicGet,
    getPostingsApiV2AccountAccountIdPostingsGet as getPostings,
    updateNameApiV2AccountAccountIdNamePut,
} from "../../client";
import { formatCurrency, getToken } from "../../utils";

const EditableAccountName = (props: { accountId: number }) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [skeleton, setSkeleton] = useState<boolean>(true);

    const loadUsername = () => {
        getAccountBasicInfoApiV2AccountAccountIdBasicGet({
            headers: { token: getToken() },
            path: { account_id: props.accountId },
        }).then((res) => {
            setValue(res.data?.name!);
            setSkeleton(false);
        });
    };

    const onSubmit = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        setIsLoading(true);
        updateNameApiV2AccountAccountIdNamePut({
            headers: { token: getToken() },
            path: { account_id: props.accountId },
            body: { new_name: value },
        }).then((res) => {
            if (res.status === 200) {
                setEditing(false);
                setIsLoading(false);
                loadUsername();
            }
        });
    };

    useEffect(() => {
        loadUsername();
    }, []);

    if (skeleton) {
        return <Skeleton width="50%" height="60px" />;
    }

    return (
        <div className="Editable-Account-Name">
            {editing ? (
                <form className="Name-Input" onSubmit={onSubmit}>
                    <Input
                        type="text"
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                        sx={{ width: "100%" }}
                    />
                    <Button type="submit">
                        {isLoading ? <CircularProgress size="15px" /> : "Save"}
                    </Button>
                    <Button onClick={() => setEditing(false)}>Cancel</Button>
                </form>
            ) : (
                <h1
                    onClick={() => {
                        setEditing(true);
                    }}>
                    {value}
                </h1>
            )}
        </div>
    );
};

const AccountBasicInfo = (props: { accountId: number }) => {
    return (
        <>
            <div className="Account-Basic-Info">
                <EditableAccountName accountId={props.accountId} />
            </div>
        </>
    );
};

export const AccountItem = () => {
    const params = useParams<{ itemId: string }>();
    const [data, setData] = useState<AccountPeriod[]>([]);
    useEffect(() => {
        getPostings({
            headers: { token: getToken() },
            path: { account_id: Number(params.itemId) },
        }).then((res) => {
            setData(res.data!);
        });
    }, []);

    const setHighlight = (periodIdx: number, rowIdx: number) => {
        setData(
            produce(data, (draft) => {
                draft[periodIdx].rows![rowIdx].highlight =
                    !draft[periodIdx].rows![rowIdx].highlight;
            }),
        );
    };

    return (
        <div className="Account-Page">
            <AccountBasicInfo accountId={Number(params.itemId)} />
            <table>
                {data?.map((period, periodIdx) => (
                    <div className="Account-Period">
                        <h2>{period.title}</h2>
                        <h3>
                            Opening balance:{" "}
                            {formatCurrency(period.opening_balance)}
                        </h3>
                        <table cellPadding={0} cellSpacing={0}>
                            {period.rows!.map((row, rowIdx) => (
                                <tr
                                    style={{
                                        backgroundColor: row.highlight
                                            ? "#b8ffd1"
                                            : "#ffffff",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setHighlight(periodIdx, rowIdx);
                                    }}>
                                    <td>
                                        {DateTime.fromISO(
                                            row.created_at,
                                        ).toFormat("LLL/d HH:mm")}
                                    </td>
                                    <td className="Amount-Cell Debit-Cell">
                                        {Number(row.debit_amount) > 0
                                            ? formatCurrency(row.debit_amount)
                                            : "-"}
                                    </td>
                                    <td className="Amount-Cell Credit-Cell">
                                        {Number(row.credit_amount) > 0
                                            ? formatCurrency(row.credit_amount)
                                            : "-"}
                                    </td>
                                    <td className="Balance-Cell">
                                        {formatCurrency(row.balance)}
                                    </td>
                                    <td>{row.comment}</td>
                                </tr>
                            ))}
                        </table>
                        <h3>
                            Closing Balance:{" "}
                            {formatCurrency(period.closing_balance)}
                        </h3>
                    </div>
                ))}
            </table>
        </div>
    );
};
