import { useEffect, useState } from "react";

import {
    getBalanceSheetApiV2StatementBalanceSheetGet as getBalanceSheet,
    StatementPart,
} from "../../client";
import { getToken } from "../../utils";
import { StatementBody } from "../StatementBody/StatementBody";

export const BalanceSheet = () => {
    const [data, setData] = useState<StatementPart[]>([]);

    useEffect(() => {
        getBalanceSheet({ headers: { token: getToken() } }).then((res) => {
            setData(res.data!);
        });
    }, []);

    return (
        <div className="Balance-Sheet">
            <h2>Balance Sheet</h2>
            <StatementBody data={data} />
        </div>
    );
};
