import { MoreVert } from "@mui/icons-material";
import { Dropdown, Menu, MenuButton, Switch, Typography } from "@mui/joy";

export interface StatementOption {
    label: string;
    checked: boolean;
    onChange?: (new_value: boolean) => void;
}

export const StatementOptions = (props: { options: StatementOption[] }) => {
    return (
        <div className="Dashboard-Widget-Options">
            <Dropdown>
                <MenuButton>
                    <MoreVert />
                </MenuButton>
                <Menu>
                    {props.options.map((option) => (
                        <Typography
                            sx={{ padding: "15px" }}
                            component="label"
                            endDecorator={
                                <Switch
                                    sx={{ ml: 1 }}
                                    checked={option.checked}
                                    onChange={() =>
                                        option.onChange!(!option.checked)
                                    }
                                />
                            }>
                            {option.label}
                        </Typography>
                    ))}
                </Menu>
            </Dropdown>
        </div>
    );
};
