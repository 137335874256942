import "./Dashboard.css";

import { BalanceSheet } from "../../elements/BalanceSheet/BalanceSheet";
import { DashboardWidget } from "../../elements/DashboardWidget/DashboardWidget";
import { IncomeStatement } from "../../elements/IncomeStatement/IncomeStatement";

export const Dashboard = () => {
    return (
        <div className="Dashboard-Page">
            <h1>Dashboard</h1>
            <div className="Dashboard-Widgets">
                <DashboardWidget key="income-statement">
                    <IncomeStatement />
                </DashboardWidget>
                <DashboardWidget key="balance-sheet-statement">
                    <BalanceSheet />
                </DashboardWidget>
            </div>
        </div>
    );
};
