import { LinearProgress } from "@mui/joy";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { GetAccountsApiV1AccountListGetResponse as getAccountsResponse } from "../../client";
import { SplitRecord } from "./SplitRecord";
import { addPosting } from "./transactionFormSlice";
import { CalcTotal } from "./Utils";

interface SplitBlockParams {
    title: string;
    debit: boolean;
    accounts: getAccountsResponse;
}

export const SplitBlock = (props: SplitBlockParams) => {
    const dispatch = useAppDispatch();
    const postings = useAppSelector((state) => state.splitForm.postings);
    const blockPostings = postings.filter((p) => p.debit === props.debit);
    const totalAmount = CalcTotal(blockPostings);

    if (props.accounts.length === 0) {
        return <LinearProgress variant="soft" />;
    }

    return (
        <div className="Split-Block">
            <span className="Split-Block-Name">
                {props.title}: {totalAmount}
            </span>
            <div className="Split-Content">
                {blockPostings.map((p) => (
                    <SplitRecord
                        key={p.posting_id}
                        accounts={props.accounts}
                        data={p}
                    />
                ))}
                <button
                    className="Add-Entry-Btn"
                    onClick={(_) =>
                        dispatch(addPosting({ debit: props.debit }))
                    }>
                    +
                </button>
            </div>
        </div>
    );
};
