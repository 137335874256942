import { formatCurrency as formatCurrencyCoinGecko } from "@coingecko/cryptoformat";

import { MoneyAmount } from "./client";

export const formatCurrency = (
    n?: number | string,
    locale?: string,
    currency?: string,
): string => {
    if (n === undefined) {
        return "";
    }
    locale = locale || "en-US";
    currency = currency || "USD";
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).format(Number(n));
};

export const formatMoneyAmount = (amount: MoneyAmount): string => {
    const finalAmount = Number(amount.amount);
    return formatCurrencyCoinGecko(
        finalAmount,
        amount.currency.code,
        "en-US",
        false,
        { decimalPlaces: amount.currency.decimals },
    );
};

export const getToken = (): string => {
    const token = localStorage.getItem("token");
    if (token === null) {
        return "";
    }
    return token;
};

export const setToken = (token: string): void => {
    localStorage.setItem("token", token);
};

export const clearToken = (): void => {
    localStorage.removeItem("token");
};
