import React from "react";

import { CircularProgress } from "@mui/joy";

interface FormSubmitProps {
    error?: string;
    ready?: boolean;
    loading?: boolean;
    onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
}

export const FormSubmit = (props: FormSubmitProps) => {
    const disabled = props.ready === undefined ? false : !props.ready;
    return (
        <div className="Submit-Block">
            {props.error && (
                <span className="Submit-Block-Error">{props.error}</span>
            )}
            {props.loading && <CircularProgress />}
            {!props.loading && (
                <button disabled={disabled} onClick={props.onSubmit}>
                    Submit
                </button>
            )}
        </div>
    );
};
