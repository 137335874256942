import { DateTime } from "luxon";
import { BaseSyntheticEvent, useState } from "react";

import { Input, Typography } from "@mui/material";

export const TransactionDate = (props: {
    value: string;
    onChange: (v: string) => void;
}) => {
    const [isChanging, setIsChanging] = useState<boolean>(false);

    const activate = () => {
        setIsChanging(true);
    };

    const onChange = (event: BaseSyntheticEvent) => {
        props.onChange(event.target.value);
    };

    return (
        <div className="TransactionDate">
            {isChanging ? (
                <Input
                    autoFocus
                    type="date"
                    value={props.value}
                    onChange={onChange}
                    onBlur={() => setIsChanging(false)}
                />
            ) : (
                <Typography
                    onClick={() => activate()}
                    sx={{ fontWeight: "bold", cursor: "pointer" }}>
                    {DateTime.fromISO(props.value).toFormat("MMM dd / yyyy")}
                </Typography>
            )}
        </div>
    );
};
