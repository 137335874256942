import "./Transactions.css";

import { produce } from "immer";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { LinearProgress } from "@mui/joy";
import { Masonry } from "@mui/lab";
import { Divider } from "@mui/material";

import {
    deleteRecordApiV2TransactionRecordIdDelete as deleteRecordV2,
    getAllTransactionsApiV2TransactionListGet as getTransactionsV2,
    JournalRecordItem,
} from "../../client";
import { getToken } from "../../utils";
import { DeletionConfirmModal } from "./DeletionModal";
import { groupRecordsByDays } from "./groupping";
import { JournalRecord } from "./JournalRecord";
import { ListPagination } from "./Pagination";

const TransactionList = () => {
    const [records, setRecords] = useState<JournalRecordItem[]>([]);
    const [deleteId, setDeleteId] = useState<number>(-1);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [pagination, setPagination] = useState<{
        offset: number;
        limit: number;
    }>({ offset: 0, limit: 30 });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadRecords = (pagination: { offset: number; limit: number }) => {
        setIsLoading(true);
        getTransactionsV2({
            headers: { token: localStorage.getItem("token") || "" },
            query: { offset: pagination.offset, limit: pagination.limit },
        }).then((response) => {
            if (response.data) {
                setRecords(response.data);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        loadRecords(pagination);
    }, [pagination]);

    if (isLoading) {
        return <LinearProgress />;
    }

    const deleteRecord = () => {
        setIsDeleting(true);
        deleteRecordV2({
            headers: { token: getToken() },
            path: { record_id: deleteId },
        }).then((_) => {
            setDeleteId(-1);
            setIsDeleting(false);
            loadRecords(pagination);
        });
    };

    const recordGroups = groupRecordsByDays(records);

    return (
        <div className="Transaction-List">
            {recordGroups.map((rg) => (
                <>
                    <Divider sx={{ width: "100%" }}> {rg.groupName} </Divider>
                    <Masonry columns={2} sx={{ width: "100%" }}>
                        {rg.records.map((record) => (
                            <JournalRecord
                                record={record}
                                onDelete={(id) => setDeleteId(id)}
                            />
                        ))}
                    </Masonry>
                </>
            ))}
            <DeletionConfirmModal
                open={deleteId !== -1}
                onClose={() => setDeleteId(-1)}
                onDelete={() => deleteRecord()}
                deletionInProgress={isDeleting}
            />
            <ListPagination
                className="TransactionList-Pagination"
                onPrev={() => {
                    setPagination(
                        produce(pagination, (draft) => {
                            draft.offset -= draft.limit;
                        }),
                    );
                }}
                onNext={() => {
                    setPagination(
                        produce(pagination, (draft) => {
                            draft.offset += draft.limit;
                        }),
                    );
                }}
                page={Math.floor(pagination.offset / pagination.limit) + 1}
            />
        </div>
    );
};

export const Transactions = () => {
    return (
        <>
            <h1>Transactions</h1>
            <div className="Page-Tabs">
                <Link to="/transaction/add/split">Add Transaction</Link>
            </div>
            <TransactionList />
        </>
    );
};
