import "./FormElements.css";

import { CSSProperties, ReactNode } from "react";

export const FormWrapper = (props: {
    children?: ReactNode;
    style?: CSSProperties;
}) => {
    return (
        <div className="Form-Wrapper" style={props.style}>
            {props.children}
        </div>
    );
};
