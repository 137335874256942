import "./Transactions.css";

import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    ModalDialog,
} from "@mui/joy";
import Modal from "@mui/joy/Modal";

export const DeletionConfirmModal = (props: {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    deletionInProgress?: boolean;
}) => {
    return (
        <Modal open={props.open} onClose={() => props.onClose()}>
            <ModalDialog variant="outlined" role="alertdialog">
                <DialogTitle>
                    <WarningRoundedIcon /> Confirmation
                </DialogTitle>
                <Divider />
                <DialogContent>
                    Are you sure you want to delete this transaction?
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={props.deletionInProgress}
                        variant="solid"
                        color="danger"
                        onClick={() => props.onDelete()}>
                        Delete Transaction
                    </Button>
                    {props.deletionInProgress && <CircularProgress />}
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};
