import "./StatementBody.css";

import { useState } from "react";
import { Link } from "react-router-dom";

import { LinearProgress } from "@mui/joy";

import { StatementPart } from "../../client";
import { formatCurrency, formatMoneyAmount } from "../../utils";
import { StatementOption, StatementOptions } from "./StatementOptions";

export const StatementBody = (props: { data: StatementPart[] }) => {
    const [showClosed, setShowClosed] = useState<boolean>(false);
    const [showZeroes, setShowZeroes] = useState<boolean>(false);

    if (props.data.length === 0) {
        return <LinearProgress />;
    }

    const statementOptions: StatementOption[] = [
        {
            label: "Show Balances <= 0",
            checked: showZeroes,
            onChange: (v: boolean) => setShowZeroes(v),
        },
        {
            label: "Show Closed Accounts",
            checked: showClosed,
            onChange: (v: boolean) => setShowClosed(v),
        },
    ];

    return (
        <div className="Statement-Body">
            <StatementOptions options={statementOptions} />
            {props.data?.map((part) => (
                <div>
                    <h3>
                        {part.title} ({formatCurrency(part.total)})
                    </h3>
                    <table cellPadding={0} cellSpacing={0}>
                        {part.rows.map((row) => {
                            if (
                                Number(row.amount_domestic.amount) === 0 &&
                                !showZeroes
                            )
                                return "";
                            if (row.closed && !showClosed) return "";
                            return (
                                <tr>
                                    <td className="Category-Name">
                                        <Link to={"/account/" + row.account_id}>
                                            {row.account_name}
                                        </Link>
                                    </td>
                                    <td className="Category-Amount">
                                        <span className="Category-Amount__Main">
                                            {formatMoneyAmount(
                                                row.amount_domestic,
                                            )}
                                        </span>
                                        {!row.domestic && (
                                            <span className="Category-Amount__Currency">
                                                {formatMoneyAmount(
                                                    row.amount_currency,
                                                )}
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
            ))}
        </div>
    );
};
