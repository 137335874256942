import { DateTime } from "luxon";

import { JournalRecordItem } from "../../client";

type JournalRecordGroup = {
    groupName: string;
    records: JournalRecordItem[];
};

const humanizeDate = (date: string): string => {
    return DateTime.fromISO(date).toFormat("MMM dd") || "Unknown date";
};

export const groupRecordsByDays = (
    records: JournalRecordItem[],
): JournalRecordGroup[] => {
    if (records.length === 0) {
        return [];
    }
    let currentGroupName = humanizeDate(records[0].created_at);
    let currentGroup: JournalRecordItem[] = [];
    const result: JournalRecordGroup[] = [];
    for (const r of records) {
        const dt = humanizeDate(r.created_at);
        if (currentGroupName !== dt) {
            result.push({ groupName: currentGroupName, records: currentGroup });
            currentGroup = [];
            currentGroupName = dt;
        }
        currentGroup.push(r);
    }
    return result;
};
