import "./TransactionAdd.css";

import { DateTime } from "luxon";
import { isEqual } from "money-math";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CircularProgress, Textarea } from "@mui/joy";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    addSplitRecordApiV2TransactionSplitPost as postRecord,
    getAccountsApiV1AccountListGet as getAccounts,
    GetAccountsApiV1AccountListGetResponse as getAccountsResponse,
} from "../../client";
import { FormWrapper } from "../../elements/FormElements/FormWrapper";
import { getToken } from "../../utils";
import { CurrencyHelper } from "./CurrencyHelper";
import { SplitBlock } from "./SplitBlock";
import { TransactionDate } from "./TransactionDate";
import { resetForm, setComment, setDate } from "./transactionFormSlice";
import { CalcTotal } from "./Utils";

export const TransactionAdd = () => {
    const [accounts, setAccounts] = useState<getAccountsResponse>([]);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const formData = useAppSelector((state) => state.splitForm);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const token = localStorage.getItem("token") as string;
        getAccounts({ headers: { token: token } }).then((res) => {
            setAccounts(res.data!);
        });
    }, []);

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        setLoading(true);
        let created_at = DateTime.fromISO(formData.created_at);
        const now = DateTime.now();
        created_at = created_at.plus({
            hours: now.hour,
            minutes: now.minute,
            seconds: now.second,
        });
        postRecord({
            body: {
                created_at: created_at.toISO() || "",
                comment: formData.comment,
                postings: formData.postings,
            },
            headers: { token: getToken() },
        }).then(() => {
            setLoading(false);
            dispatch(resetForm());
            navigate("/transaction");
        });
    };

    useEffect(() => {
        const validateForm = (): string => {
            const total = CalcTotal(formData.postings);
            const debit = CalcTotal(formData.postings, true);
            const credit = CalcTotal(formData.postings, false);
            if (!formData.created_at) {
                return "Form date is not set";
            }
            if (formData.postings.length === 0) {
                return "No Debit or Credit postings";
            }
            if (isEqual(total, "0.00")) {
                return "Transaction amount must be >= than 0";
            }
            if (!isEqual(debit, credit)) {
                return "Total Debit ≠ Total Credit";
            }
            const unfilledPostings = formData.postings.filter(
                (p) => !p.amount || p.account_id === -1,
            );
            if (unfilledPostings.length > 0) {
                return "Not all postings are filled";
            }
            if (!formData.comment) {
                return "Description is required";
            }
            return "";
        };
        setError(validateForm());
    }, [formData]);

    return (
        <div className="Page" style={{ display: "flex" }}>
            <FormWrapper>
                <div className="FormHeader">
                    <h2>New Split Record</h2>
                    <TransactionDate
                        value={formData.created_at}
                        onChange={(v) => {
                            dispatch(setDate(v));
                        }}
                    />
                </div>
                <div className="Split">
                    <SplitBlock
                        title="Debit"
                        accounts={accounts}
                        debit={true}
                    />
                    <SplitBlock
                        title="Credit"
                        accounts={accounts}
                        debit={false}
                    />
                </div>
                <div className="Comment-Block">
                    <Textarea
                        color="neutral"
                        value={formData.comment}
                        onChange={(e) => dispatch(setComment(e.target.value))}
                        placeholder="Comment"
                        variant="outlined"
                        sx={{
                            "&:before": { display: "none" },
                            fontFamily: "Saira",
                        }}
                    />
                </div>
                <div className="Final-Block">
                    <button
                        disabled={error !== "" || loading}
                        onClick={handleSubmit}>
                        Submit
                    </button>
                    {error && <span className="Form-Error">{error}</span>}
                    {loading && (
                        <span className="Form-Loader">
                            <CircularProgress size="sm" />
                        </span>
                    )}
                </div>
            </FormWrapper>
            <CurrencyHelper />
        </div>
    );
};
