import { configureStore } from "@reduxjs/toolkit";

import { TransactionSplitFormSclice } from "../pages/TransactionAdd/transactionFormSlice";

export const store = configureStore({
    reducer: {
        splitForm: TransactionSplitFormSclice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
