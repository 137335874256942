import { resolveSoa } from "dns";
import { ReactNode, SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AddBusiness } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";

import {
    createCompanyApiV1CompanyPost,
    joinCompanyApiV1CompanyJoinPost as joinPost,
} from "../../client";
import { getToken, setToken } from "../../utils";

const CommonInput = (props: {
    label?: string;
    adorment?: ReactNode;
    value?: string;
    onChange?: (v: string) => void;
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
                p: "15px 0",
            }}>
            <Box sx={{ mr: 1, my: -0.5 }}>{props.adorment}</Box>
            <TextField
                onChange={(e) =>
                    props.onChange === undefined
                        ? null
                        : props.onChange(e.target.value)
                }
                value={props.value}
                label={props.label}
                variant="standard"
                sx={{ width: "100%" }}
            />
        </Box>
    );
};

const FormHeader = (props: { children: ReactNode }) => {
    return (
        <Typography
            variant="h2"
            sx={{
                fontSize: 25,
                m: "10px 0",
                fontWeight: "bold",
                fontFamily: "Saira",
            }}>
            {props.children}
        </Typography>
    );
};

const FormContainer = (props: { children: ReactNode }) => {
    return (
        <Box sx={{ bgcolor: "#ffffff", p: "20px", borderRadius: "10px" }}>
            {props.children}
        </Box>
    );
};

const CreateCompanyForm = () => {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState<string>("");

    const submitForm = async () => {
        const res = await createCompanyApiV1CompanyPost({
            headers: { token: getToken() },
            body: { name: companyName },
        });
        if (res.status !== 200) {
            console.log("Error");
        }
        navigate("/dashboard");
    };

    return (
        <FormContainer>
            <FormHeader>Create a New Company</FormHeader>
            <Typography variant="body2">
                You are a completely new user
            </Typography>
            <Box
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                }}>
                <CommonInput
                    label="Company name"
                    value={companyName}
                    onChange={(v) => setCompanyName(v)}
                    adorment={<AddBusiness />}
                />
                <Button type="submit">Create</Button>
            </Box>
        </FormContainer>
    );
};

const JoinCompanyForm = () => {
    const navigate = useNavigate();
    const [secret, setSecret] = useState<string>("");
    const submit = (e: SyntheticEvent) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        if (token === null) {
            navigate("/signin");
            return;
        }
        joinPost({ body: { secret: secret }, headers: { token: token } })
            .then((res) => navigate("/dashboard"))
            .catch((err) => {});
    };

    return (
        <div className="Auth-Form">
            <h1>Join an existing company</h1>
            <div className="Input-Group">
                <input
                    type="password"
                    placeholder="Secret Key"
                    value={secret}
                    onChange={(e) => setSecret(e.target.value)}
                />
            </div>
            <button disabled={secret === ""} onClick={submit}>
                Submit
            </button>
        </div>
    );
};

export const CreateCompanyPage = () => {
    return (
        <div className="Auth-Page">
            <CreateCompanyForm />
            <JoinCompanyForm />
        </div>
    );
};
