import { produce } from "immer";
import {
    BaseSyntheticEvent,
    createContext,
    Dispatch,
    useContext,
    useReducer,
} from "react";

import { Delete } from "@mui/icons-material";
import { Button } from "@mui/material";

import {
    getCurrencyIcon,
    MonetaryInput,
} from "../../elements/FormElements/CurrencyInput";
import { FormWrapper } from "../../elements/FormElements/FormWrapper";

class CurrencyHelperData {
    mainCurrency: string = "USD";
    foreignCurrency: string = "CRC";
    mainCurrencyValue: string = "";
    foreignCurrencyValue: string = "";
    itemValue: string = "";
    items: string[] = [];
}

class CurrencyHelperContextClass {
    state: CurrencyHelperData = new CurrencyHelperData();
    dispatch: Dispatch<Action>;
}

const CurrencyHelperContext = createContext<CurrencyHelperContextClass>(
    new CurrencyHelperContextClass(),
);

type Action =
    | { type: "totalMainUpdate"; payload: string }
    | { type: "totalForeignUpdate"; payload: string }
    | { type: "itemUpdate"; payload: string }
    | { type: "addItemAmount" }
    | { type: "deleteItemAmount"; payload: number }
    | { type: "clearItems" };

const reducer = (
    state: CurrencyHelperData,
    action: Action,
): CurrencyHelperData => {
    switch (action.type) {
        case "totalMainUpdate":
            return { ...state, mainCurrencyValue: action.payload };
        case "totalForeignUpdate":
            return { ...state, foreignCurrencyValue: action.payload };
        case "itemUpdate":
            return { ...state, itemValue: action.payload };
        case "addItemAmount":
            if (Number(state.itemValue) === 0) {
                return state;
            }
            const items = [...state.items, state.itemValue];
            return { ...state, items: items, itemValue: "" };
        case "deleteItemAmount":
            const newItems = [
                ...state.items.slice(0, action.payload),
                ...state.items.slice(action.payload + 1),
            ];
            return { ...state, items: newItems };
        case "clearItems":
            return produce(state, (draft) => {
                draft.items = [];
            });
        default:
            return state;
    }
};

const LocalCurrencyInput = (props: {
    currencyCode: string;
    value: string;
    onChange: (value: string) => void;
    testId?: string;
}) => {
    return (
        <MonetaryInput
            onFocus={(e) => e.target.select()}
            testId={props.testId}
            value={props.value}
            onChange={props.onChange}
            currencyCode={props.currencyCode}
            inputStyles={{
                border: "none",
                borderBottom: "1px solid black",
                fontSize: "18px",
                paddingLeft: "30px",
                outline: "none",
            }}
            iconStyles={{
                position: "absolute",
                width: "18px",
                top: "6px",
                left: "4px",
            }}
        />
    );
};

const ItemTag = (props: { value: string; idx: number }) => {
    const { state, dispatch } = useContext(CurrencyHelperContext);
    const icon = getCurrencyIcon(state.foreignCurrency);
    const containerStyles = {
        backgroundColor: "#f3f3f3",
        padding: "7px",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
    };
    return (
        <div className="ItemValue" style={containerStyles}>
            <img src={icon} alt="currencyIcon" style={{ height: "18px" }} />
            <span>{props.value}</span>
            <button
                style={{ padding: "1px", border: "none", cursor: "pointer" }}
                onClick={() =>
                    dispatch({ type: "deleteItemAmount", payload: props.idx })
                }>
                <Delete sx={{ fontSize: 18 }} />
            </button>
        </div>
    );
};

const CurrencyHelperTotal = (props: { rate: number }) => {
    const { state, dispatch } = useContext(CurrencyHelperContext);
    const toShowRate =
        isNaN(props.rate) || !isFinite(props.rate) || props.rate === 0;
    return (
        <>
            <h3>Check Total</h3>
            <div
                className="TotalInputs"
                style={{ display: "flex", width: "100%", gap: "10px" }}>
                <LocalCurrencyInput
                    currencyCode={state.mainCurrency}
                    value={state.mainCurrencyValue}
                    onChange={(v) =>
                        dispatch({ type: "totalMainUpdate", payload: v })
                    }
                />
                <LocalCurrencyInput
                    currencyCode={state.foreignCurrency}
                    value={state.foreignCurrencyValue}
                    onChange={(v) =>
                        dispatch({ type: "totalForeignUpdate", payload: v })
                    }
                />
            </div>
            <span aria-label="exchange-rate">
                Exchange Rate: {toShowRate ? "-" : props.rate.toFixed(2)}
            </span>
        </>
    );
};

const TotalRow = (props: {
    currency: string;
    testId: string;
    amount: Number;
    title: string;
}) => {
    return (
        <p>
            {props.title}:{" "}
            <b>
                {props.currency}{" "}
                <span data-testid={props.testId}>
                    {props.amount.toFixed(2)}
                </span>
            </b>
        </p>
    );
};

const CurrencyHelperResult = (props: { rate: number }) => {
    const { state } = useContext(CurrencyHelperContext);
    const totalItems = state.items.reduce((prev, cur) => {
        return prev + Number(cur);
    }, 0);
    const remaining = Number(state.foreignCurrencyValue) - Number(totalItems);
    const totalItemsDomestic = totalItems / props.rate;
    const remainingDomestic = remaining / props.rate;
    return (
        <div className="Items-Total">
            <h3>Result</h3>
            <div className="Items-Total-Block">
                <TotalRow
                    title="Total Currency"
                    testId="amount-total-currency"
                    amount={totalItems}
                    currency={state.foreignCurrency}
                />
                <TotalRow
                    title="Remaining Currency"
                    testId="amount-remaining-currency"
                    amount={remaining}
                    currency={state.foreignCurrency}
                />
                <TotalRow
                    title="Total Domestic"
                    testId="amount-total-domestic"
                    amount={totalItemsDomestic || 0}
                    currency={state.mainCurrency}
                />
                <TotalRow
                    title="Remaining Domestic"
                    testId="amount-remaining-domestic"
                    amount={remainingDomestic || 0}
                    currency={state.mainCurrency}
                />
            </div>
        </div>
    );
};

const CurrencyHelperInputForm = () => {
    const { state, dispatch } = useContext<CurrencyHelperContextClass>(
        CurrencyHelperContext,
    );
    const onSubmit = (event: BaseSyntheticEvent) => {
        event.preventDefault();
        dispatch({ type: "addItemAmount" });
    };
    return (
        <div>
            <form
                onSubmit={onSubmit}
                style={{ position: "relative" }}
                aria-label="add-item-form">
                <LocalCurrencyInput
                    aria-label="add-item-input"
                    testId={"add-item-input"}
                    currencyCode={state.foreignCurrency}
                    value={state.itemValue}
                    onChange={(v) =>
                        dispatch({ type: "itemUpdate", payload: v })
                    }
                />
                <input
                    type="submit"
                    value={"Add"}
                    style={{
                        position: "absolute",
                        display: "block",
                        top: "5px",
                        right: "0",
                        height: "80%",
                        width: "40px",
                        padding: 0,
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                    }}
                />
            </form>
            <div
                style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginTop: 20,
                }}>
                {state.items.map((item, i) => (
                    <ItemTag value={item} idx={i} key={i} />
                ))}
            </div>
        </div>
    );
};

const RootElement = () => {
    const { state, dispatch } = useContext(CurrencyHelperContext);
    const rate =
        Number(state.foreignCurrencyValue) / Number(state.mainCurrencyValue);
    return (
        <FormWrapper
            style={{
                width: "400px",
                gap: "20px",
                display: "flex",
                flexDirection: "column",
            }}>
            <h2>Currency Expense</h2>
            <CurrencyHelperTotal rate={rate} />
            <h3>Items</h3>
            <CurrencyHelperInputForm />
            <Button
                data-testid="clearbutton"
                onClick={() => dispatch({ type: "clearItems" })}>
                Clear
            </Button>
            <CurrencyHelperResult rate={rate} />
        </FormWrapper>
    );
};

export const CurrencyHelper = () => {
    const [state, dispatch] = useReducer(reducer, new CurrencyHelperData());

    return (
        <CurrencyHelperContext.Provider
            value={{ state: state, dispatch: dispatch }}>
            <RootElement />
        </CurrencyHelperContext.Provider>
    );
};
